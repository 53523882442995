import { Route, Routes } from "react-router-dom";
import AddSupervisor from "./Frontend/AdminDashboard/AddSupervisor";
import Applications from "./Frontend/AdminDashboard/Applications";
import AssignSupervisor from "./Frontend/AdminDashboard/AssignSupervisor";
import Dashboard from "./Frontend/AdminDashboard/Dashboard";
import EditIntern from "./Frontend/AdminDashboard/EditIntern";
import EditSupervisor from "./Frontend/AdminDashboard/EditSupervisor";
import Interns from "./Frontend/AdminDashboard/Interns";
import Positions from "./Frontend/AdminDashboard/Positions";
import ScheduleInterview from "./Frontend/AdminDashboard/ScheduleInterview";
import SelectedApplicants from "./Frontend/AdminDashboard/SelectedApplicants";
import AdminLayout from "./Frontend/AdminDashboard/Shared/AdminLayout";
import Supervisors from "./Frontend/AdminDashboard/Supervisors";
import ViewDetails from "./Frontend/AdminDashboard/ViewDetails";
import ApplicationStatus from "./Frontend/ApplicationStatus";
import CreateApplication from "./Frontend/CreateApplication";
import DefaultLayout from "./Frontend/DefaultLayout";
import ErrorPage from "./Frontend/ErrorPage";
import ForgotPassword from "./Frontend/ForgotPassword";
import EditProfile from "./Frontend/EditProfile";

import Profile from "./Frontend/InternDashboard/Profile";
import Layout from "./Frontend/InternDashboard/Shared/Layout";
import Task from "./Frontend/InternDashboard/Task";
import Tasks from "./Frontend/InternDashboard/Tasks";
import Login from "./Frontend/Login";
import RequireAuth from "./Frontend/RequireAuth";
import ResetPassword from "./Frontend/ResetPassword";
import AssignedTasks from "./Frontend/SupervisorDashboard/AssignedTasks";
import AssignTask from "./Frontend/SupervisorDashboard/AssignTask";
import EditTask from "./Frontend/SupervisorDashboard/EditTask";
import Home from "./Frontend/SupervisorDashboard/Home";
import SupervisorLayout from "./Frontend/SupervisorDashboard/Shared/SupervisorLayout";
import TaskDetails from "./Frontend/SupervisorDashboard/TaskDetails";
import Unauthorized from "./Frontend/Unauthorized";
import ProfilePage from "./Frontend/SupervisorDashboard/ProfilePage";
import MyProfile from "./Frontend/AdminDashboard/MyProfile";
import EditDetails from "./Frontend/AdminDashboard/EditDetails";
import SignUp from "./Frontend/SignUp";
import RequestPasswordReset from "./Frontend/RequestPasswordReset";
import InternPerformance from "./Frontend/InternPerformance";

function App() {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        {/* {Public Routes} */}
        <Route index element={<CreateApplication />} />
        <Route path="applicationStatus" element={<ApplicationStatus />} />
        <Route path="login" element={<Login />} />
        <Route path="signUp" element={<SignUp />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="/resetPassword/:token" element={<ForgotPassword />} />
        <Route path="requestResetPassword" element={<RequestPasswordReset />} />

        {/* Intern Routes */}
        <Route element={<RequireAuth allowedRoles={["INT"]} />}>
          <Route path="internDashboard" element={<Layout />}>
            <Route index element={<Tasks />} />
            <Route path="/internDashboard/tasks/:id" element={<Task />} />
            <Route path="/internDashboard/profile" element={<Profile />} />
            <Route
              path="/internDashboard/resetPassword"
              element={<ResetPassword />}
            />
            <Route
              path="/internDashboard/editProfile/:id"
              element={<EditProfile />}
            />
          </Route>
        </Route>
        {/* Intern Routes */}

        {/* Supervisor Routes */}
        <Route element={<RequireAuth allowedRoles={["SUP"]} />}>
          <Route path="/supervisorDashboard" element={<SupervisorLayout />}>
            <Route index element={<Home />} />
            <Route
              path="/supervisorDashboard/assignTask/:name/:id"
              element={<AssignTask />}
            />
            <Route
              path="/supervisorDashboard/assignedTasks"
              element={<AssignedTasks />}
            />
            <Route
              path="/supervisorDashboard/taskDetails/:id"
              element={<TaskDetails />}
            />
            <Route
              path="/supervisorDashboard/taskDetails/:id/edit"
              element={<EditTask />}
            />
            <Route
              path="/supervisorDashboard/performanceDetails/:id"
              element={<InternPerformance />}
            />
            <Route
              path="/supervisorDashboard/resetPassword"
              element={<ResetPassword />}
            />
            <Route
              path="/supervisorDashboard/profile"
              element={<ProfilePage />}
            />
            <Route
              path="/supervisorDashboard/editProfile/:id"
              element={<EditProfile />}
            />
          </Route>
        </Route>
        {/* Supervisor Routes */}

        {/* Admin Routes */}
        <Route element={<RequireAuth allowedRoles={["ADM"]} />}>
          <Route path="/adminDashboard" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />
            <Route
              path="/adminDashboard/applications"
              element={<Applications />}
            />
            <Route
              path="/adminDashboard/applications/:id/scheduleInterview"
              element={<ScheduleInterview />}
            />
            <Route
              path="/adminDashboard/selectedApplicants"
              element={<SelectedApplicants />}
            />
            <Route
              path="/adminDashboard/interviewDetails/viewDetails/:id"
              element={<ViewDetails />}
            />
            <Route
              path="/adminDashboard/supervisors"
              element={<Supervisors />}
            />
            <Route
              path="/adminDashboard/supervisors/add"
              element={<AddSupervisor />}
            />
            <Route
              path="/adminDashboard/supervisors/:id/edit"
              element={<EditSupervisor />}
            />
            <Route path="/adminDashboard/interns" element={<Interns />} />
            <Route
              path="/adminDashboard/interns/:id/edit"
              element={<EditIntern />}
            />
            <Route
              path="/adminDashboard/performanceDetails/:id"
              element={<InternPerformance />}
            />
            <Route
              path="/adminDashboard/assignSupervisor"
              element={<AssignSupervisor />}
            />
            <Route path="/adminDashboard/positions" element={<Positions />} />
            <Route path="/adminDashboard/profile" element={<MyProfile />} />
            <Route
              path="/adminDashboard/editProfile/:id"
              element={<EditDetails />}
            />
            <Route
              path="/adminDashboard/resetPassword"
              element={<ResetPassword />}
            />
          </Route>
        </Route>
        {/* Admin Routes */}

        {/* Page not found route */}
        <Route path="*" element={<ErrorPage />} />
        {/* Page not found route */}
      </Route>
    </Routes>
  );
}

export default App;
