import React, { useState } from "react";
import axiosClient from "./AxiosClient";
import LoadingComponent from "./LoadingComponent";
import swal from "sweetalert";
import { Link, useParams } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function ForgotPassword() {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({});
  const [Loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { token } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("token", token);
    formData.append("password", password);
    setLoading(true);

    axiosClient
      .post(`/Reset`, formData)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMessage(null);
          setPassword("");
          setMessage({});
          swal({
            title: "Success!",
            text: "Password Reset Successfully!",
            icon: "success",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setPassword("");
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Password could not be reset!", "error");
        }
      });
  };

  return (
    <section className="px-4 h-screen bg-slate-200 flex items-center justify-center lg:px-0 ">
      <div className="form-container bg-white rounded-lg w-96 p-8 text-gray-700 shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Reset Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container mb-6 relative">
            <label htmlFor="password" className="font-semibold text-lg">
              New Password
            </label>
            <input
              type={visible ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
            />
            {visible ? (
              <AiOutlineEyeInvisible
                className="absolute right-2 top-10 cursor-pointer text-gray-500"
                size={24}
                onClick={() => setVisible(false)}
              />
            ) : (
              <AiOutlineEye
                className="absolute right-2 top-10 cursor-pointer text-gray-500"
                size={24}
                onClick={() => setVisible(true)}
              />
            )}
            <span className="text-red-400">{message?.password}</span>
          </div>
          <button className="mt-1 w-full py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150">
            {Loading ? <LoadingComponent /> : "Reset Password"}
          </button>

          <div className="text-center text-sm mt-4">
            <Link to="/login" className="text-blue-500 underline">
              Login Now?
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default ForgotPassword;
