import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "./AxiosClient";
import LoadingComponent from "./LoadingComponent";

function RequestPasswordReset() {
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    setLoading(true);

    axiosClient
      .post("/PasswordReset", formData)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMessage(null);
          setEmail("");
          setMessage({});
          swal({
            title: "Success!",
            text: "Please Check your email!",
            icon: "success",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not make the request!", "error");
        }
      });
  };

  return (
    <section className="px-4 h-screen bg-slate-200 flex items-center justify-center lg:px-0 ">
      <div className="form-container bg-white rounded-lg w-96 p-8 text-gray-700 shadow-lg">
        <h1 className="text-2xl font-bold mb-4">Request Reset Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container mb-2">
            <label htmlFor="email" className="font-semibold text-lg">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
            />
            <span className="text-xs">
              * Please use the same email as used while logging in
            </span>
            <span className="text-red-400 block">{message?.email}</span>
          </div>
          <button className="mt-1 w-full py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150">
            {Loading ? <LoadingComponent /> : "Send Request"}
          </button>

          <div className="text-center text-sm mt-4">
            <Link to="/login" className="text-blue-500 underline">
              Login Now?
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default RequestPasswordReset;
