import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";

function EditTask() {
  const [Loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);

  const [message, setMessage] = useState({});

  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const getTaskDetails = () => {
    setLoading(true);
    axiosClient
      .get(`/Task/${id}/edit`)
      .then((res) => {
        if (res.status === 200) {
          setTask(res?.data?.task?.Task);
          setDescription(res?.data?.task?.Description);
          setDeadline(res?.data?.task?.Deadline);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Task Details", "error");
      });
  };

  useEffect(() => {
    getTaskDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      Task: task,
      Description: description,
      Deadline: deadline,
    };
    setSubmitLoading(true);
    axiosClient
      .put(`/Task/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setSubmitLoading(false);
          swal("Success", "Task Updated", "success");
          setMessage({});
          navigate(`/supervisorDashboard/taskDetails/${id}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        setSubmitLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not update Task", "error");
        }
      });
  };

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <>
          <h2 className="text-2xl mb-4 font-semibold text-gray-700">
            Edit Task
          </h2>
          <div className="text-gray-700 flex flex-col bg-white rounded-md shadow-lg">
            <div className="form-box p-4">
              <form onSubmit={handleSubmit}>
                <div className="input-container mb-6 w-full">
                  <label htmlFor="task" className="font-semibold text-lg">
                    Task
                  </label>
                  <input
                    type="text"
                    name="Task"
                    id="task"
                    defaultValue={task}
                    onChange={(e) => setTask(e.target.value)}
                    className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                  />
                  <span className="text-red-400">{message?.Task}</span>
                </div>
                <div className="input-container mb-4 w-full">
                  <label
                    htmlFor="taskDescription"
                    className="font-semibold text-lg"
                  >
                    Task Description
                  </label>
                  <textarea
                    name="Description"
                    id="taskDescription"
                    cols="30"
                    rows="3"
                    defaultValue={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 py-2 focus:outline-gray-500"
                  ></textarea>
                  <span className="text-red-400">{message?.Description}</span>
                </div>
                <div className="input-container mb-6 w-full">
                  <label htmlFor="deadline" className="font-semibold text-lg">
                    Deadline
                  </label>
                  <input
                    type="date"
                    name="Deadline"
                    id="deadline"
                    defaultValue={deadline}
                    min={new Date().toISOString().split("T")[0]}
                    onChange={(e) => setDeadline(e.target.value)}
                    className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                  />
                  <span className="text-red-400">{message?.Deadline}</span>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                  >
                    {SubmitLoading ? <LoadingComponent /> : "Save Changes"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default EditTask;
