import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineX, HiOutlineLogout } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import { ADMIN_DASHBOARD_SIDEBAR_LINKS } from "../../consts/navigation";
import classNames from "classnames";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../AxiosClient";
import LoadingComponent from "../../LoadingComponent";
import swal from "sweetalert";

const linkClasses =
  "flex items-center gap-2 font-medium px-3 py-2 hover:bg-neutral-500 hover:no-underline rounded-sm text-base ease-linear transition-all duration-150";

function AdminSidebar(props) {
  const [Loading, setLoading] = useState(false);
  const { setAuth, setToken } = useStateContext();

  const logout = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosClient
      .post("/logout")
      .then(() => {
        setLoading(false);
        setAuth({});
        setToken(null);
        localStorage.removeItem("Roles");
        localStorage.removeItem("Name");
        localStorage.removeItem("user_id");
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not logout", "error");
      });
  };
  return (
    <>
      <div
        className={`${
          props.open ? "tranlate-x-0 " : "-translate-x-80"
        } fixed inset-0 z-50 my-2 ml-1 h-[calc(100vh-18px)] w-64 rounded-md flex flex-col bg-neutral-800 p-3 text-white transition-transform duration-300 xl:translate-x-0 xl:inset-0 xl:z-0`}
      >
        <div className="flex items-center justify-between gap-2 px-3 py-3">
          <div className="logo-box flex items-center gap-2">
            <MdDashboard fontSize={30} />
            <span className="text-neutral-100 text-2xl">DASHBOARD</span>
          </div>
          <HiOutlineX
            onClick={() => props.setOpen(!props.open)}
            className="xl:hidden"
            fontSize={24}
          />
        </div>
        <div className="flex-1 py-8 flex flex-col gap-1">
          {ADMIN_DASHBOARD_SIDEBAR_LINKS.map((item) => (
            <SidebarLink key={item.key} item={item} />
          ))}
        </div>

        <div className="flex flex-col gap-0.5 pt-2 border-t border-slate-200">
          <div
            className={classNames("text-red-500 cursor-pointer", linkClasses)}
            onClick={logout}
          >
            <span className="text-xl">
              <HiOutlineLogout />
            </span>
            {Loading ? <LoadingComponent /> : "Logout"}
          </div>
        </div>
      </div>
      {props.open ? (
        <>
          <div className="opacity-25 fixed inset-0 z-40 bg-black xl:hidden"></div>
        </>
      ) : null}
    </>
  );
}

function SidebarLink({ item }) {
  const { pathname } = useLocation();
  return (
    <Link
      to={item.path}
      className={classNames(
        pathname === item.path
          ? "bg-neutral-700 text-white"
          : "text-neutral-400",
        linkClasses
      )}
    >
      <span className="text-xl">{item.icon}</span>
      {item.label}
    </Link>
  );
}

export default AdminSidebar;
