import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "./AxiosClient";
import LoadingComponent from "./LoadingComponent";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({});
  const [Loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    setLoading(true);

    axiosClient
      .post("/Account/Activate", formData)
      .then((res) => {
        if (res.status === 201) {
          swal("Success", "Sign Up Successfull. You can login now", "success");
          setLoading(false);
          setMessage({});
          setEmail("");
          setPassword("");
          navigate("/login");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (
          (error?.response && error?.response?.status === 422) ||
          error?.response?.status === 401
        ) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not Sign Up", "error");
        }
      });
  };

  return (
    <section className="px-4 h-screen bg-slate-200 flex items-center justify-center lg:px-0 ">
      <div className="form-container bg-white rounded-lg w-96 p-8 text-gray-700 shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Sign Up</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container mb-4">
            <label htmlFor="email" className="font-semibold text-lg">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
            />
            <span className="text-xs">
              * Please use the same email as used while applying
            </span>
            <span className="text-red-400 block">{message?.email}</span>
          </div>
          <div className="input-container mb-6 relative">
            <label htmlFor="password" className="font-semibold text-lg">
              Password
            </label>
            <input
              type={visible ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
            />
            {visible ? (
              <AiOutlineEyeInvisible
                className="absolute right-2 top-10 cursor-pointer text-gray-500"
                size={24}
                onClick={() => setVisible(false)}
              />
            ) : (
              <AiOutlineEye
                className="absolute right-2 top-10 cursor-pointer text-gray-500"
                size={24}
                onClick={() => setVisible(true)}
              />
            )}
            <span className="text-red-400">{message?.password}</span>
          </div>
          <button className="mt-1 w-full py-2 bg-sky-500 text-white font-bold rounded-md hover:bg-sky-600 ease-linear transition-all duration-150">
            {Loading ? <LoadingComponent /> : "Sign Up"}
          </button>
          <div className="text-center flex flex-col text-sm mt-4">
            <Link to="/login" className="text-blue-500 underline mb-2">
              Login Now
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default SignUp;
