import React from "react";

export default function Unauthorized() {
  return (
    <div
      id="error-page"
      className="flex flex-col justify-center items-center h-screen text-gray-700"
    >
      <h1 className=" text-8xl text-red-600 mb-8">Oops!</h1>
      <p className="text-4xl mb-4">
        Sorry, you are unauthorized to access this page
      </p>
    </div>
  );
}
