import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import Table, { SelectColumnFilter } from "../consts/table";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";
import Status from "../consts/application-status";

function SelectedApplicants() {
  const [applicants, setApplicants] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);

  const getApplicants = () => {
    setLoading(true);
    axiosClient
      .get(`Admin/User/Interviews`)
      .then((res) => {
        if (res.status === 200) {
          setApplicants(res?.data?.Applicants);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Interview Details", "error");
      });
  };

  useEffect(() => {
    getApplicants();
  }, []);

  const acceptApplication = (id) => {
    const data = {
      ApplicationStatus: "Passed",
    };
    setSubmitLoading(true);
    axiosClient
      .put(`Apply/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Applicant Accepted", "success");
          setSubmitLoading(false);
          getApplicants();
        }
      })
      .catch(() => {
        setSubmitLoading(false);
        swal("Error", "Application could not be Accepted", "error");
      });
  };

  const rejectApplication = (id) => {
    const data = {
      ApplicationStatus: "Declined",
    };
    setSubmitLoading(true);
    axiosClient
      .put(`Apply/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Applicant Rejected", "success");
          getApplicants();
          setSubmitLoading(false);
          getApplicants();
        }
      })
      .catch(() => {
        setSubmitLoading(false);
        swal("Error", "Could not reject application", "error");
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Phone Number",
        accessor: "PhoneNumber",
      },
      {
        Header: "Application For",
        accessor: "Position",
      },
      {
        Header: "Status",
        accessor: "ApplicationStatus",
        Cell: ({ row }) => {
          return Status(row.original.ApplicationStatus);
        },
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Action",
        accessor: (data) => data.status,
        Cell: ({ row }) => {
          if (row.original.ApplicationStatus === "Accepted For Interview") {
            return (
              <div className=" flex gap-3">
                <button
                  className="bg-emerald-500 px-2 py-1 text-white font-medium hover:bg-emerald-600 rounded-md ease-linear transition-all duration-150"
                  onClick={() => {
                    swal({
                      title: "Warning",
                      text: "Do you want to accept the application for the applied position?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((value) => {
                      if (value) {
                        acceptApplication(row.original.id);
                      }
                    });
                  }}
                >
                  Accept
                </button>

                <button
                  className="bg-red-500 px-2 py-1 text-white font-medium hover:bg-red-600 rounded-md ease-linear transition-all duration-150"
                  onClick={() => {
                    swal({
                      title: "Warning",
                      text: "Do you want to reject the application for the applied position?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((value) => {
                      if (value) {
                        rejectApplication(row.original.id);
                      }
                    });
                  }}
                >
                  Decline
                </button>
              </div>
            );
          } else if (row.original.ApplicationStatus === "Declined") {
            return (
              <div className=" flex gap-3">
                <Link
                  to={`/adminDashboard/interviewDetails/viewDetails/${row.original.id}`}
                >
                  <button className="bg-orange-500 px-2 py-1 text-white font-medium hover:bg-orange-600 rounded-md ease-linear transition-all duration-150">
                    View Info
                  </button>
                </Link>

                <Link
                  to={`/adminDashboard/applications/${row.original.id}/scheduleInterview`}
                >
                  <button className="bg-emerald-500 px-2 py-1 text-white font-medium hover:bg-emerald-600 rounded-md ease-linear transition-all duration-150">
                    Re-accept
                  </button>
                </Link>
              </div>
            );
          } else {
            return (
              <div className=" flex gap-3">
                <Link
                  to={`/adminDashboard/interviewDetails/viewDetails/${row.original.id}`}
                >
                  <button className="bg-orange-500 px-2 py-1 text-white font-medium hover:bg-orange-600 rounded-md ease-linear transition-all duration-150">
                    View Info
                  </button>
                </Link>
              </div>
            );
          }
        },
      },
    ],
    []
  );
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="text-gray-700 grid grid-cols-1 px-2 lg:px-4 w-full">
          <div>
            <h1 className="text-2xl font-semibold">Selected Applicants</h1>
          </div>
          <div className="mt-4">
            {SubmitLoading ? (
              <LoadingComponent />
            ) : (
              <Table columns={columns} data={applicants} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SelectedApplicants;
