import React, { useEffect, useState } from "react";
import Table, { SelectColumnFilter } from "../consts/table";
import { Link } from "react-router-dom";
import axiosClient from "../AxiosClient";
import LoadingScreen from "../LoadingScreen";
import swal from "sweetalert";
import TaskStatus from "../consts/task-status";

function AssignedTasks() {
  const [Loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get("/Task")
      .then((res) => {
        setTableData(res?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Tasks", "error");
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Intern",
        accessor: "assignedto.Name",
        Filter: SelectColumnFilter, // new
        filter: "includes", // new
      },
      {
        Header: "Task",
        accessor: "Task",
      },
      {
        Header: "Deadline",
        accessor: "Deadline",
      },
      {
        Header: "Task Status",
        accessor: "Status",
        Cell: ({ row }) => {
          return TaskStatus(row.original.Status);
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <Link to={`/supervisorDashboard/taskDetails/${row.original.id}`}>
            <button className="bg-sky-500 px-2 py-1 text-white font-medium hover:bg-sky-600 rounded-md ease-linear transition-all duration-150">
              View Task Details
            </button>
          </Link>
        ),
      },
    ],
    []
  );
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="text-gray-700 grid grid-cols-1 px-2 lg:px-4 w-full">
          <div>
            <h1 className="text-2xl font-semibold">Interns</h1>
          </div>
          <div className="mt-4">
            <Table columns={columns} data={tableData} />
          </div>
        </div>
      )}
    </>
  );
}

export default AssignedTasks;
