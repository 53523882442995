import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";

function ScheduleInterview() {
  const [Loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [message, setMessage] = useState({});

  const { id } = useParams();
  const statusRef = useRef();

  const navigate = useNavigate();

  const getApplicantDetails = () => {
    setLoading(true);
    axiosClient
      .get(`Apply/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setEmail(res?.data?.Applicant?.Email);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Intern Email", "error");
      });
  };

  useEffect(() => {
    getApplicantDetails();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    const status = statusRef.current.value;

    const data = {
      Date: emailBody,
      ApplicationStatus: status,
    };

    axiosClient
      .put(`Apply/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setEmailBody("");
          swal("Success", "Email sent Successfully", "success");
          setSubmitLoading(false);
          setMessage({});
          navigate("/adminDashboard/selectedApplicants");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Email was not sent", "error");
        }
      });
  };
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <>
          <h2 className="text-2xl mb-4 font-semibold text-gray-700">
            Schedule an Interview for this Applicant
          </h2>

          <div className="text-gray-700 flex flex-col bg-white rounded-md shadow-lg">
            <div className="form-box p-4">
              <form onSubmit={handleSubmit}>
                <div className="input-container mb-6 w-full">
                  <label htmlFor="email" className="font-semibold text-lg">
                    Applicant Email
                  </label>
                  <input
                    type="email"
                    name="Email"
                    id="email"
                    readOnly
                    defaultValue={email}
                    className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                  />
                </div>
                <input
                  type="hidden"
                  name="ApplicationStatus"
                  id="ApplicationStatus"
                  value={"Accepted"}
                  ref={statusRef}
                  className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                />
                <div className="input-container mb-4 w-full">
                  <label htmlFor="task" className="font-semibold text-lg">
                    Interview Date
                  </label>
                  <input
                    type="date"
                    name="Date"
                    id="Date"
                    value={emailBody}
                    min={new Date().toISOString().split("T")[0]}
                    onChange={(e) => setEmailBody(e.target.value)}
                    className="w-full mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 py-2 focus:outline-gray-500"
                  />
                  <span className="text-red-400">{message?.Date}</span>
                </div>
                <div className="flex items-center justify-end">
                  <button
                    type="submit"
                    className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                  >
                    {SubmitLoading ? <LoadingComponent /> : "Schedule"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ScheduleInterview;
