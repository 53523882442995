import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import LoadingScreen from "../LoadingScreen";

function ProfilePage() {
  const user_id = localStorage.getItem("user_id");
  const [Loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(`/User/${user_id}`)
      .then((res) => {
        if (res.status === 200) {
          setUserDetails(res?.data?.User);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get profile details", "error");
      });
  }, []);

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="flex items-center justify-center">
          <div className="profile-card bg-white p-6 shadow-lg rounded-md">
            <div className="user-icon-box mb-4 flex justify-center">
              <FaUserCircle fontSize={160} color={"gray"} />
            </div>

            <hr className=" w-full border-gray-400 mb-4" />
            <div className="profile-info flex flex-col text-lg text-gray-700">
              <p className="mb-4">
                <span className="font-semibold">Name:</span> {userDetails?.Name}
              </p>
              <p className="mb-4">
                <span className="font-semibold">Email:</span>{" "}
                {userDetails.Email}
              </p>
              <p className="mb-4">
                <span className="font-semibold">Phone Number:</span>{" "}
                {userDetails.PhoneNumber}
              </p>
              <p className="mb-2">
                <span className="font-semibold">Department:</span>{" "}
                {userDetails.Position}
              </p>
            </div>
            <div className="flex justify-end">
              <Link
                to={`/supervisorDashboard/editProfile/${userDetails.user_id}`}
                className="text-sky-500 font-bold rounded-md hover:underline ease-linear transition-all duration-150"
              >
                Edit Profile
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePage;
