import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import Table, { SelectColumnFilter } from "../consts/table";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";

function Applications() {
  const [Loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [LoadCv, setLoadCv] = useState(false);
  const [LoadLetter, setLoadLetter] = useState(false);
  const [applications, setApplications] = useState([]);

  const getApplications = () => {
    setLoading(true);
    axiosClient
      .get(`Admin/User/Applicants`)
      .then((res) => {
        if (res.status === 200) {
          setApplications(res?.data?.Applicants);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Applications", "error");
      });
  };

  const getCV = (id) => {
    setLoadCv(true);
    axiosClient
      .get(`Apply/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setLoadCv(false);
          window.open(res?.data?.Cv);
        }
      })
      .catch(() => {
        setLoadCv(false);
        swal("Error", "Could Not Load Cv", "error");
      });
  };

  const getLetter = (id) => {
    setLoadLetter(true);
    axiosClient
      .get(`Apply/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setLoadLetter(false);
          window.open(res?.data?.AttachmentLetter);
        }
      })
      .catch(() => {
        setLoadLetter(false);
        swal("Error", "Could Not Load Letter", "error");
      });
  };

  useEffect(() => {
    getApplications();
  }, []);

  const declineApplication = (id) => {
    const data = {
      ApplicationStatus: "Declined",
    };
    setSubmitLoading(true);
    axiosClient
      .put(`Apply/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Application Declined", "success");
          setSubmitLoading(false);
          getApplications();
        }
      })
      .catch(() => {
        setSubmitLoading(false);
        swal("Error", "Could Not Decline Application", "error");
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Phone Number",
        accessor: "PhoneNumber",
      },
      {
        Header: "Application For",
        accessor: "Position",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "CV",

        Cell: ({ row }) => (
          <div className=" flex gap-3">
            <button
              className="bg-orange-500 px-2 py-1 text-white font-medium hover:bg-orange-600 rounded-md ease-linear transition-all duration-150"
              onClick={() => {
                getCV(row.original.id);
              }}
            >
              {LoadCv ? <LoadingComponent /> : "View"}
            </button>
          </div>
        ),
      },
      {
        Header: "Letter",
        accessor: "url_to_attachment_letter",
        Cell: ({ row }) => (
          <div className=" flex gap-3">
            <button
              className="bg-orange-500 px-2 py-1 text-white font-medium hover:bg-orange-600 rounded-md ease-linear transition-all duration-150"
              onClick={() => {
                getLetter(row.original.id);
              }}
            >
              {LoadLetter ? <LoadingComponent /> : "View"}
            </button>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className=" flex gap-3">
            <Link
              to={`/adminDashboard/applications/${row.original.id}/scheduleInterview`}
            >
              <button className="bg-emerald-500 px-2 py-1 text-white font-medium hover:bg-emerald-600 rounded-md ease-linear transition-all duration-150">
                Accept
              </button>
            </Link>

            <button
              className="bg-red-500 px-2 py-1 text-white font-medium hover:bg-red-600 rounded-md ease-linear transition-all duration-150"
              onClick={() => {
                swal({
                  title: "Warning",
                  text: "Are you sure you want to decline this application?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((value) => {
                  if (value) {
                    declineApplication(row.original.id);
                  }
                });
              }}
            >
              Decline
            </button>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="text-gray-700 grid grid-cols-1 px-2 lg:px-4 w-full">
          <div>
            <h1 className="text-2xl font-semibold">Intership Applications</h1>
          </div>
          <div className="mt-4">
            {SubmitLoading ? (
              <LoadingComponent />
            ) : (
              <Table columns={columns} data={applications} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Applications;
