import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "./AxiosClient";
import Rating from "./consts/ratings";
import LoadingScreen from "./LoadingScreen";

function InternPerformance() {
  const [Loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [taskDetails, setTaskDetails] = useState([]);
  const [overallRating, setOverallRating] = useState("");
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(`/Supervisor/Perfomance/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setUserDetails(res?.data?.User);
          setTaskDetails(res?.data?.Tasks);
          setOverallRating(res?.data?.Overall);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get performance details", "error");
      });
  }, []);
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="grid grid-cols-1">
          <div className="ratings-card bg-white p-6 shadow-lg rounded-md">
            <h1 className="text-2xl text-gray-700 mb-2 font-semibold">
              Perfomance Details - {userDetails.Name}
            </h1>
            <hr className=" w-full border-gray-400 mb-4" />
            {taskDetails.length === 0 ? (
              <h2 className="text-xl text-gray-700 mb-4 font-medium">
                No tasks have been assigned yet
              </h2>
            ) : (
              <>
                <h2 className="text-xl text-gray-700 mb-4 font-medium">
                  Task Ratings
                </h2>
                {taskDetails.map((task, index) => (
                  <>
                    <div
                      key={index}
                      className="task-ratings-info flex justify-between items-center mb-3"
                    >
                      <span className="text-gray-700">
                        {index + 1}. {task.Task}
                      </span>
                      <span className="text-sm text-gray-700">
                        {task.Rating === null && task.Status === "Assigned"
                          ? "Not completed"
                          : task.Rating !== null && task.Status === "Assigned"
                          ? "Not completed"
                          : task.Rating === null && task.Status === "Completed"
                          ? "Not rated"
                          : Rating(task.Rating)}
                      </span>
                    </div>
                    <hr className="mb-2" />
                  </>
                ))}
                <div className="mt-8 flex justify-between items-center">
                  <p className="text-2xl text-gray-700 font-medium">
                    Overall Performance
                  </p>
                  {Rating(overallRating)}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default InternPerformance;
