import React, { useEffect, useRef, useState } from "react";
import TaskStatus from "../consts/task-status";
import { Link, useParams } from "react-router-dom";
import axiosClient from "../AxiosClient";
import LoadingScreen from "../LoadingScreen";
import swal from "sweetalert";
import LoadingComponent from "../LoadingComponent";
import { FaUserCircle } from "react-icons/fa";
import Rating from "../consts/ratings";

function TaskDetails() {
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [error, setError] = useState({});
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [Load, setLoad] = useState(false);
  const [ratingLoad, setRatingLoad] = useState(false);
  const [comments, setComments] = useState([]);
  const [remark, setRemark] = useState("");
  const [updateremark, setUpdateRemark] = useState("");
  const [showAddRating, setShowAddRating] = useState(false);
  const [rating, setRating] = useState(0);

  const [task, setTask] = useState({});
  const { id } = useParams();
  const userIDRef = useRef();
  const name = localStorage.getItem("Name");
  const [editable, setEditable] = useState(null);

  const getTaskDetails = () => {
    setLoading(true);
    axiosClient
      .get(`/Task/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const TaskDetails = res?.data?.task;
          const CommentDetails = res?.data?.comment;
          setTask({ TaskDetails });
          setComments(CommentDetails);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Task Details", "error");
      });
  };

  useEffect(() => {
    getTaskDetails();
    setEditable(null);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const userID = userIDRef.current.value;
    const formData = new FormData();
    formData.append("Taskid", id);
    formData.append("Intern_comment", remark);
    formData.append("Userid", userID);
    axiosClient
      .post("/Comment", formData)
      .then((res) => {
        if (res.status === 200) {
          setSubmitLoading(false);
          setRemark("");
          setMessage({});
          swal("Success", "Remark Added Successfully", "success");
          getTaskDetails();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not add remark", "error");
        }
      });
  };

  const updateComment = (id) => {
    setLoad(true);
    const data = {
      Comment: updateremark,
    };

    axiosClient
      .put(`/Comment/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Remark Updated Successfully", "success");
          setEditable(null);
          setError({});
          setLoad(false);
          getTaskDetails();
        }
      })
      .catch((error) => {
        setLoad(false);
        if (error?.response && error?.response?.status === 422) {
          setError(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not update remark", "error");
        }
      });
  };

  const enableEditable = (index) => {
    setEditable(index);
  };

  const addRating = () => {
    setRatingLoad(true);
    console.log(rating);
    const data = {
      rating: rating,
    };
    axiosClient
      .post(`/Supervisor/Task/Rate/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setRatingLoad(false);
          setShowAddRating(false);
          swal("Success", "Rating added", "success");
          getTaskDetails();
        }
      })
      .catch(() => {
        setRatingLoad(false);
        swal("Error", "Could not add rating", "error");
      });
  };

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-white rounded-sm p-4 flex flex-col shadow-md text-gray-700">
          <p className="mb-4 text-2xl">
            Intern - {task?.TaskDetails?.assignedto?.Name}
          </p>

          <div className="flex flex-col justify-start mb-4 gap-3 lg:flex-row lg:gap-6 lg:items-center">
            <div className="flex items-center">
              <p className="text-3xl font-medium">{task?.TaskDetails?.Task}</p>
            </div>
            <div className="flex items-center gap-4">
              <span>{TaskStatus(task?.TaskDetails?.Status)}</span>
              <span>
                <span className="capitalize py-1 px-2 rounded-md text-xs text-red-600 bg-red-100">
                  Deadline: {task?.TaskDetails?.Deadline}
                </span>
              </span>
              {task?.TaskDetails?.Rating !== null &&
              task?.TaskDetails?.Status === "Completed" ? (
                <span>{Rating(task?.TaskDetails?.Rating)}</span>
              ) : null}
            </div>
          </div>
          <div className="description-box mb-2">
            <p className="font-medium text-lg mb-2">Task Description</p>
            <textarea
              className="w-full border-2 focus:outline-0 pl-2 pt-2 "
              name="task-description"
              id="task-description"
              cols="30"
              rows="3"
              readOnly
              value={task?.TaskDetails?.Description}
              placeholder="Task description will appear here..."
            ></textarea>
          </div>
          <div className="flex items-center justify-end gap-3">
            <Link to={"edit"} className="w-full lg:w-40">
              <button className="mb-4 w-full lg:w-40 py-2 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 ease-linear transition-all duration-150">
                Edit Task
              </button>
            </Link>
            {task?.TaskDetails?.Status === "Completed" &&
            task?.TaskDetails?.Rating === null ? (
              <button
                className="mb-4 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                onClick={() => setShowAddRating(true)}
              >
                Add Rating
              </button>
            ) : task?.TaskDetails?.Status === "Completed" &&
              task?.TaskDetails?.Rating >= 0 ? (
              <button
                className="mb-4 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                onClick={() => setShowAddRating(true)}
              >
                Edit Rating
              </button>
            ) : null}
          </div>

          {showAddRating ? (
            <>
              <label htmlFor="rating" className="font-semibold text-lg">
                Task Rating
              </label>
              <input
                type="range"
                name="rating"
                id="task"
                defaultValue={
                  task?.TaskDetails?.Rating === null
                    ? rating
                    : task?.TaskDetails?.Rating
                }
                onChange={(e) => setRating(e.target.value)}
                className="sm:w-auto lg:w-80 mb-3"
              />
              <span className="text-lg">Rating: {rating} %</span>
              <span className="text-red-400">{message?.Task}</span>
              <div className="flex justify-end mb-2">
                <button
                  type="submit"
                  className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                  onClick={addRating}
                >
                  {ratingLoad ? <LoadingComponent /> : "Save"}
                </button>
              </div>
            </>
          ) : null}

          <hr className="border mb-4 border-gray-300" />
          <div className="supervisor-box text-gray-700 mb-2">
            <p className="mb-2 font-medium text-lg">Comments and Remarks</p>

            {comments.length > 0 ? (
              comments.map((comment, index) => (
                <article
                  key={comment.id}
                  className="p-4 mb-6 text-base bg-slate-50 rounded-lg"
                >
                  {editable === index ? (
                    <>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center justify-center gap-2">
                          <FaUserCircle fontSize={20} color={"gray"} />
                          <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-medium">
                            {comment.madeby}
                          </p>
                        </div>
                        {comment.madeby === name ? (
                          <button
                            className="p-2 bg-emerald-500 text-sm text-white font-medium hover:bg-emerald-600 rounded-md"
                            type="button"
                            onClick={() => {
                              updateComment(comment.id);
                            }}
                          >
                            {Load ? <LoadingComponent /> : "Save"}
                          </button>
                        ) : null}
                      </div>
                      <input
                        className="text-gray-800 w-full p-1 border-2 border-gray-600"
                        defaultValue={comment.Comments}
                        onChange={(e) => setUpdateRemark(e.target.value)}
                      />
                      <span className="text-red-400">{error?.Comment}</span>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center justify-center gap-2">
                          <FaUserCircle fontSize={20} color={"gray"} />
                          <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-medium">
                            {comment.madeby}
                          </p>
                        </div>
                        {comment.madeby === name ? (
                          <button
                            className="p-1 text-sm font-medium hover:underline hover:text-gray-900"
                            type="button"
                            onClick={() => {
                              enableEditable(index);
                            }}
                          >
                            Edit
                          </button>
                        ) : null}
                      </div>
                      <input
                        className="text-gray-800 w-full p-1"
                        disabled
                        defaultValue={comment.Comments}
                      />
                    </>
                  )}
                </article>
              ))
            ) : (
              <p>No comments have been made yet</p>
            )}
          </div>
          <hr className="border mb-4 border-gray-300" />
          <div className="supervisor-box text-gray-700 mb-2">
            <p className="mb-2 font-medium text-lg">Add Remarks</p>
            <form>
              <span className="text-red-400">{message?.Intern_comment}</span>
              <textarea
                className="w-full border-2 focus:outline-0 pl-2 pt-2 mb-2"
                name="Intern_comment"
                id="Intern_comment"
                cols="30"
                rows="4"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Add remarks for your intern here..."
              ></textarea>
              <input
                type="hidden"
                value={task?.TaskDetails?.assignedby?.user_id}
                ref={userIDRef}
              />
            </form>
            <div className="btn-box flex justify-end ">
              <button
                type="submit"
                className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                onClick={handleSubmit}
              >
                {SubmitLoading ? <LoadingComponent /> : "Add Remark"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TaskDetails;
