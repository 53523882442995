import React, { useState } from "react";
import axiosClient from "./AxiosClient";
import { useStateContext } from "./contexts/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";
import swal from "sweetalert";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({});
  const [Loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { setAuth, setToken } = useStateContext();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    setLoading(true);

    axiosClient
      .post("/login", formData)
      .then(({ data }) => {
        const user_info = data?.user;
        const username = data?.user?.Name;
        const user_id = data?.user?.user_id;
        const roles = data?.Role;
        const token = data?.token;

        localStorage.setItem("Roles", roles);
        localStorage.setItem("Name", username);
        localStorage.setItem("user_id", user_id);

        setAuth({ user_info, roles });
        setToken(token);
        setLoading(false);
        setMessage({});

        if (data.Role[0] === "INT") {
          navigate("/internDashboard");
        } else if (data.Role[0] === "SUP") {
          navigate("/supervisorDashboard");
        } else if (data.Role[0] === "ADM") {
          navigate("/adminDashboard");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          setMessage(error?.response?.data);
          swal("Error", "Could not Login", "error");
        }
      });
  };

  return (
    <section className="px-4 h-screen bg-slate-200 flex items-center justify-center lg:px-0 ">
      <div className="form-container bg-white rounded-lg w-96 p-8 text-gray-700 shadow-lg">
        <h1 className="text-3xl font-bold mb-4">Login</h1>
        <form onSubmit={handleSubmit}>
          <span className="text-red-400">{message?.message}</span>
          <div className="input-container mb-6">
            <label htmlFor="email" className="font-semibold text-lg">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
            />
            <span className="text-red-400">{message?.email}</span>
          </div>
          <div className="input-container mb-6 relative">
            <label htmlFor="password" className="font-semibold text-lg">
              Password
            </label>
            <input
              type={visible ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
            />
            {visible ? (
              <AiOutlineEyeInvisible
                className="absolute right-2 top-10 cursor-pointer text-gray-500"
                size={24}
                onClick={() => setVisible(false)}
              />
            ) : (
              <AiOutlineEye
                className="absolute right-2 top-10 cursor-pointer text-gray-500"
                size={24}
                onClick={() => setVisible(true)}
              />
            )}
            <span className="text-red-400 mb-0">{message?.password}</span>
          </div>
          <button className="mt-1 w-full py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150">
            {Loading ? <LoadingComponent /> : "Sign In"}
          </button>
          <div className="text-center flex flex-col text-sm mt-4">
            <Link
              to="/requestResetPassword"
              className="text-blue-500 underline mb-2"
            >
              Forgot Password? Reset Now
            </Link>

            <Link to="/" className="text-green-500 underline">
              Home
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Login;
