import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import Table from "../consts/table";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";

function Positions() {
  const [showModal, setShowModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [reload, setReload] = useState(false);
  const [positions, setPositions] = useState([]);
  const [newPosition, setNewPosition] = useState("");
  const [editablePosition, setEditablePosition] = useState("");
  const [editedPosition, setEditedPosition] = useState("");
  const [editablePositionID, setEditablePositionID] = useState("");
  const [message, setMessage] = useState({});
  const [error, setError] = useState({});

  const getPositions = () => {
    setLoading(true);
    axiosClient
      .get(`/Position`)
      .then((res) => {
        if (res.status === 200) {
          setPositions(res?.data?.Positions);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Positions", "error");
      });
  };

  useEffect(() => {
    getPositions();
  }, []);

  const addPosition = (e) => {
    e.preventDefault();

    const data = {
      Position: newPosition,
    };
    setLoad(true);
    axiosClient
      .post("/Position", data)
      .then((res) => {
        if (res.status === 201) {
          swal("Success", "New Position Added", "success");
          setNewPosition("");
          setLoad(false);
          getPositions();
        }
      })
      .catch((error) => {
        setLoad(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not add new Position", "error");
        }
      });
  };

  const getPositionDetails = (id) => {
    axiosClient
      .get(`/Position/${id}/edit`)
      .then((res) => {
        if (res.status === 200) {
          setEditablePosition(res?.data?.position.Position);
          setEditablePositionID(res?.data?.position.id);
          setShowModal(true);
          setLoading(false);
          setMessage({});
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Position Details", "error");
      });
  };

  const editPosition = (id) => {
    const data = {
      Position: editedPosition,
    };
    setSubmitLoading(true);
    axiosClient
      .put(`/Position/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Position Updated Successfully", "success");
          setSubmitLoading(false);
          setShowModal(false);
          getPositions();
          setError({});
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setError(error?.response?.data?.errors);
        } else {
          swal("Error", "Could Not Update Position", "error");
        }
      });
  };

  const deletePosition = (id) => {
    setReload(true);
    axiosClient
      .delete(`/Position/${id}`)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Position Deleted", "success");
          getPositions();
          setReload(false);
        }
      })
      .catch(() => {
        swal("Error", "Could not delete Position", "error");
        setReload(false);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Position",
        accessor: "Position",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className=" flex gap-3">
            <button
              className="bg-emerald-500 px-2 py-1 text-white font-medium hover:bg-emerald-600 rounded-md ease-linear transition-all duration-150"
              onClick={() => getPositionDetails(row.original.id)}
            >
              Edit
            </button>

            <button
              className="bg-red-500 px-2 py-1 text-white font-medium hover:bg-red-600 rounded-md ease-linear transition-all duration-150"
              onClick={() => {
                swal({
                  title: "Warning",
                  text: "Are you sure you want to delete this position?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((value) => {
                  if (value) {
                    deletePosition(row.original.id);
                  }
                });
              }}
            >
              {reload ? <LoadingComponent /> : "Delete"}
            </button>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="text-gray-700 grid grid-cols-1 px-2 lg:px-4 w-full">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold">Positions</h1>
            <div className="flex gap-3">
              <input
                type="text"
                value={newPosition}
                onChange={(e) => setNewPosition(e.target.value)}
                className="rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              />
              <button
                className="bg-blue-500 px-2 py-1 text-white font-medium hover:bg-blue-600 rounded-md ease-linear transition-all duration-150"
                onClick={addPosition}
              >
                {load ? <LoadingComponent /> : "Add Position"}
              </button>
              <span className="text-red-400">{message?.Position}</span>
            </div>
          </div>
          <div className="mt-4">
            <Table columns={columns} data={positions} />
          </div>

          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto lg:w-1/3 my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">Edit Position</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <div className="my-4 text-slate-500 text-lg leading-relaxed">
                        <input
                          type="text"
                          defaultValue={editablePosition}
                          onChange={(e) => setEditedPosition(e.target.value)}
                          className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                        />
                        <span className="text-red-400">{error?.Position}</span>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                      <button
                        className="text-white bg-emerald-500 hover:bg-emerald-600 rounded-md font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => editPosition(editablePositionID)}
                      >
                        {SubmitLoading ? <LoadingComponent /> : "Save Changes"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default Positions;
