import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import TaskStatus from "../consts/task-status";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";
import { FaUserCircle } from "react-icons/fa";
import Rating from "../consts/ratings";

function Task() {
  const [message, setMessage] = useState({});
  const [error, setError] = useState({});

  const [Loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [Load, setLoad] = useState(false);

  const [comment, setComment] = useState("");

  const [remarks, setRemarks] = useState([]);
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const userIDRef = useRef();
  const [ReloadTask, setReloadTask] = useState(false);
  const taskRef = useRef();

  const name = localStorage.getItem("Name");
  const [editable, setEditable] = useState(null);
  const [updateremark, setUpdateRemark] = useState("");

  const getTask = () => {
    setLoading(true);
    axiosClient
      .get(`/Task/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const TaskDetails = res?.data?.task;
          const supervisor = res?.data?.Supervisor;
          const CommentDetails = res?.data?.comment;
          setDetails({ TaskDetails, supervisor, CommentDetails });
          setRemarks(CommentDetails);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Task Details", "error");
      });
  };

  useEffect(() => {
    getTask();
    setEditable(null);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const userID = userIDRef.current.value;
    const formData = new FormData();
    formData.append("Taskid", id);
    formData.append("Intern_comment", comment);
    formData.append("Userid", userID);
    axiosClient
      .post("/Comment", formData)
      .then((res) => {
        if (res.status === 200) {
          setSubmitLoading(false);
          setComment("");
          setMessage({});
          getTask();
          swal("Success", "Comment Added Successfully", "success");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not add comment", "error");
        }
      });
  };

  const updateComment = (id) => {
    setLoad(true);
    const data = {
      Comment: updateremark,
    };

    axiosClient
      .put(`/Comment/${id}`, data)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Comment Updated Successfully", "success");
          setEditable(null);
          setLoad(false);
          getTask();
          setError({});
        }
      })
      .catch((error) => {
        setLoad(false);
        if (error?.response && error?.response?.status === 422) {
          setError(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not update comment", "error");
        }
      });
  };

  const Complete = () => {
    const taskID = taskRef.current.value;
    setReloadTask(true);
    setEditable(null);
    axiosClient
      .put(`Task/Status/${taskID}`)
      .then((res) => {
        if (res.status === 200) {
          axiosClient.get(`/Task/${id}`).then((res) => {
            if (res.status === 200) {
              const TaskDetails = res?.data?.task;
              const supervisor = res?.data?.Supervisor;
              const CommentDetails = res?.data?.comment;
              setDetails({ TaskDetails, supervisor, CommentDetails });
              setReloadTask(false);
            }
          });
        }
      })
      .catch(() => {
        setReloadTask(false);
        swal("Error", "Could not Complete Tasks", "error");
      });
  };

  const enableEditable = (index) => {
    setEditable(index);
  };

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-white rounded-sm p-4 flex flex-col shadow-md text-gray-700">
          {ReloadTask ? (
            <LoadingComponent />
          ) : (
            <>
              <div className="flex flex-col justify-start mb-4 gap-3 lg:flex-row lg:gap-6 lg:items-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="h-[2rem] w-[1.2rem]"
                    onClick={Complete}
                    value={details?.TaskDetails?.id}
                    ref={taskRef}
                    defaultChecked={
                      details?.TaskDetails?.Status === "Completed"
                        ? "checked"
                        : ""
                    }
                  />
                  <span className="text-3xl font-medium pl-4">
                    {details?.TaskDetails?.Task}
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  <span>{TaskStatus(details?.TaskDetails?.Status)}</span>
                  <span>
                    <span className="capitalize py-1 px-2 rounded-md text-xs text-red-600 bg-red-100">
                      Deadline: {details?.TaskDetails?.Deadline}
                    </span>
                  </span>
                  {details?.TaskDetails?.Status === "Completed" &&
                  details?.TaskDetails?.Rating !== null ? (
                    <span>{Rating(details?.TaskDetails?.Rating)}</span>
                  ) : null}
                </div>
              </div>
              <div className="description-box mb-2">
                <p className="font-medium mb-2 text-lg">Task Description</p>
                <textarea
                  className="w-full border-2 focus:outline-0 pl-2 pt-1 mb-2"
                  name="task-description"
                  id="task-description"
                  cols="30"
                  rows="3"
                  readOnly
                  value={details?.TaskDetails?.Description}
                  placeholder="Task description will appear here..."
                ></textarea>
                <h2 className="mb-2">
                  Assigned by{" "}
                  <span className="font-medium">{details?.supervisor}</span>
                </h2>
              </div>

              <hr className="border mb-4 border-gray-300" />
              <div className="supervisor-box text-gray-700 mb-2">
                <p className="mb-2 text-lg font-medium">Comments and Remarks</p>
                {remarks.length > 0 ? (
                  remarks.map((comment, index) => (
                    <article
                      key={comment.id}
                      className="p-4 mb-6 text-base bg-slate-50 rounded-lg"
                    >
                      {editable === index ? (
                        <>
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center justify-center gap-2">
                              <FaUserCircle fontSize={20} color={"gray"} />
                              <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-medium">
                                {comment.madeby}
                              </p>
                            </div>

                            {comment.madeby === name ? (
                              <button
                                className="p-2 bg-emerald-500 text-sm text-white font-medium hover:bg-emerald-600 rounded-md"
                                type="button"
                                onClick={() => {
                                  updateComment(comment.id);
                                }}
                              >
                                {Load ? <LoadingComponent /> : "Save"}
                              </button>
                            ) : null}
                          </div>
                          <input
                            className="text-gray-800 w-full p-1 border-2 border-gray-600"
                            defaultValue={comment.Comments}
                            onChange={(e) => setUpdateRemark(e.target.value)}
                          />
                          <span className="text-red-400">{error?.Comment}</span>
                        </>
                      ) : (
                        <>
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center justify-center gap-2">
                              <FaUserCircle fontSize={20} color={"gray"} />
                              <p className="inline-flex items-center mr-3 text-sm text-gray-900 font-medium">
                                {comment.madeby}
                              </p>
                            </div>
                            {comment.madeby === name ? (
                              <button
                                className="p-1 text-sm font-medium hover:underline hover:text-gray-900"
                                type="button"
                                onClick={() => {
                                  enableEditable(index);
                                }}
                              >
                                Edit
                              </button>
                            ) : null}
                          </div>
                          <input
                            className="text-gray-800 w-full p-1"
                            disabled
                            defaultValue={comment.Comments}
                          />
                        </>
                      )}
                    </article>
                  ))
                ) : (
                  <p>There are no comments or remarks added currently</p>
                )}
              </div>
              <hr className="border mb-4 border-gray-300" />
              <div className="supervisor-box text-gray-700 mb-2">
                <p className="mb-2 font-medium text-lg">Add Comments</p>
                <form>
                  <textarea
                    className="w-full border-2 focus:outline-0 pl-2 pt-2 mb-2"
                    name="Intern_comment"
                    id="Intern_comment"
                    cols="30"
                    rows="4"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Add comments for your supervisor here..."
                  ></textarea>
                  <span className="text-red-400">
                    {message?.Intern_comment}
                  </span>
                  <input
                    type="hidden"
                    value={details?.TaskDetails?.AssignedTo}
                    ref={userIDRef}
                  />
                </form>

                <div className="btn-box flex justify-end">
                  <button
                    onClick={handleSubmit}
                    className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                  >
                    {SubmitLoading ? <LoadingComponent /> : "Add Comment"}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
export default Task;
