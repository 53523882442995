import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import Table, { SelectColumnFilter } from "../consts/table";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";

function Supervisors() {
  const [Loading, setLoading] = useState(false);
  const [Load, setLoad] = useState(false);
  const [supervisors, setSupervisors] = useState([]);

  const getSupervisors = () => {
    setLoading(true);
    axiosClient
      .get(`Admin/User/Supervisors`)
      .then((res) => {
        if (res.status === 200) {
          setSupervisors(res?.data?.Supervisors);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Supervisors", "error");
      });
  };

  useEffect(() => {
    getSupervisors();
  }, []);

  const deleteSuperviosr = (id) => {
    setLoad(true);
    axiosClient
      .delete(`/User/${id}`)
      .then((res) => {
        if (res.status === 200) {
          swal("Success", "Supervisor Deleted", "success");
          getSupervisors();
          setLoad(false);
        }
      })
      .catch(() => {
        swal("Error", "Could not delete Supervisor", "error");
        setLoad(false);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Phone Number",
        accessor: "PhoneNumber",
      },
      {
        Header: "Department",
        accessor: "Position",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className=" flex gap-3">
            <Link
              to={`/adminDashboard/supervisors/${row.original.user_id}/edit`}
            >
              <button className="bg-emerald-500 px-2 py-1 text-white font-medium hover:bg-emerald-600 rounded-md ease-linear transition-all duration-150">
                Edit
              </button>
            </Link>
            <Link to={""}>
              <button
                className="bg-red-500 px-2 py-1 text-white font-medium hover:bg-red-600 rounded-md ease-linear transition-all duration-150"
                onClick={() => {
                  swal({
                    title: "Warning",
                    text: "Are you sure you want to delete this supervisor?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((value) => {
                    if (value) {
                      deleteSuperviosr(row.original.user_id);
                    }
                  });
                }}
              >
                Delete
              </button>
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="text-gray-700 grid grid-cols-1 px-2 lg:px-4 w-full">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold">Supervisors</h1>
            <Link to={"/adminDashboard/supervisors/add"}>
              <button className="bg-blue-500 px-2 py-1 text-white font-medium hover:bg-blue-600 rounded-md ease-linear transition-all duration-150">
                Add Supervisor
              </button>
            </Link>
          </div>
          <div className="mt-4">
            {Load ? (
              <LoadingComponent />
            ) : (
              <Table columns={columns} data={supervisors} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Supervisors;
