import React from "react";

function ErrorPage() {
  return (
    <div
      id="error-page"
      className="flex flex-col justify-center items-center h-screen text-gray-700"
    >
      <h1 className=" text-8xl text-red-600 mb-8">Oops!</h1>
      <p className="text-4xl mb-4">
        Sorry, the page you are looking for cannot be found!
      </p>
    </div>
  );
}

export default ErrorPage;
