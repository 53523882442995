import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import Table, { SelectColumnFilter } from "../consts/table";
import LoadingScreen from "../LoadingScreen";

function Home() {
  const [tableData, setTableData] = useState([]);
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axiosClient
      .get("/Supervisor/User/MyInterns")
      .then((res) => {
        setTableData(res?.data?.MyInterns);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Interns ", "error");
      });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Phone Number",
        accessor: "PhoneNumber",
      },
      {
        Header: "Department",
        accessor: "Position",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <Link
            to={`/supervisorDashboard/assignTask/${row.original.Name}/${row.original.user_id}`}
          >
            <button className="bg-emerald-500 px-2 py-1 text-white font-medium hover:bg-emerald-600 rounded-md ease-linear transition-all duration-150">
              Assign Task
            </button>
          </Link>
        ),
      },
      {
        Header: "Performance",
        // accessor: "action",
        Cell: ({ row }) => (
          <Link
            to={`/supervisorDashboard/performanceDetails/${row.original.user_id}`}
          >
            <button className="bg-sky-500 px-2 py-1 text-white font-medium hover:bg-sky-600 rounded-md ease-linear transition-all duration-150">
              View
            </button>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="text-gray-700 grid grid-cols-1 px-2 lg:px-4 w-full">
          <div>
            <h1 className="text-2xl font-semibold">My Interns</h1>
          </div>
          <div className="mt-4">
            <Table columns={columns} data={tableData} />
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
