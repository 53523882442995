import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

function Layout() {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-row bg-neutral-100 h-screen w-screen overflow-hidden">
      <Sidebar open={open} setOpen={setOpen} />
      <div className={`flex flex-col flex-1 xl:ml-[16rem]`}>
        <Navbar open={open} setOpen={setOpen} />
        <div className="flex-1 p-4 min-h-0 overflow-auto">{<Outlet />}</div>
      </div>
    </div>
  );
}

export default Layout;
