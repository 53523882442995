import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useStateContext } from "./contexts/ContextProvider";

const RequireAuth = ({ allowedRoles }) => {
  const { token } = useStateContext();
  const location = useLocation();
  const roles = localStorage.getItem("Roles");

  return allowedRoles?.includes(roles) && token ? (
    <Outlet />
  ) : !allowedRoles?.includes(roles) && token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
