import { FaTasks, FaUsers, FaList, FaFileUpload } from "react-icons/fa";
import {
  MdSpaceDashboard,
  MdRateReview,
  MdOutlineSupervisorAccount,
} from "react-icons/md";

export const INTERN_DASHBOARD_SIDEBAR_LINKS = [
  {
    key: "tasks",
    label: "My Tasks",
    path: "/internDashboard",
    icon: <FaTasks />,
  },
];

export const SUPERVISOR_DASHBOARD_SIDEBAR_LINKS = [
  {
    key: "interns",
    label: "My Interns",
    path: "/supervisorDashboard",
    icon: <FaUsers />,
  },
  {
    key: "assigned-tasks",
    label: "Assigned Tasks",
    path: "/supervisorDashboard/assignedTasks",
    icon: <FaList />,
  },
];

export const ADMIN_DASHBOARD_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/adminDashboard",
    icon: <MdSpaceDashboard />,
  },
  {
    key: "applications",
    label: "Applications",
    path: "/adminDashboard/applications",
    icon: <FaFileUpload />,
  },
  {
    key: "interviews",
    label: "Selected Applicants",
    path: "/adminDashboard/selectedApplicants",
    icon: <MdRateReview />,
  },
  {
    key: "supervisors",
    label: "Supervisors",
    path: "/adminDashboard/supervisors",
    icon: <MdOutlineSupervisorAccount />,
  },
  {
    key: "interns",
    label: "Interns",
    path: "/adminDashboard/interns",
    icon: <FaUsers />,
  },
  {
    key: "positions",
    label: "Positions",
    path: "/adminDashboard/positions",
    icon: <FaList />,
  },
];
