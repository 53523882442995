import React from "react";

function Rating(rating) {
  switch (true) {
    case rating > 50 && rating < 75:
      return (
        <span className="capitalize py-1 px-2 rounded-md text-xs text-yellow-600 bg-yellow-100">
          Rating: {rating}%
        </span>
      );
    case rating >= 75:
      return (
        <span className="capitalize py-1 px-2 rounded-md text-xs text-green-600 bg-green-100">
          Rating: {rating}%
        </span>
      );
    case rating <= 50:
      return (
        <span className="capitalize py-1 px-2 rounded-md text-xs text-red-600 bg-red-100">
          Rating: {rating}%
        </span>
      );
    default:
      return (
        <span className="capitalize py-1 px-2 rounded-md text-xs text-gray-600 bg-gray-100">
          Rating: {rating}%
        </span>
      );
  }
}

export default Rating;
