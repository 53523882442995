import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import LoadingScreen from "../LoadingScreen";
import Rating from "../consts/ratings";

function Profile() {
  const user_id = localStorage.getItem("user_id");
  const [Loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [taskDetails, setTaskDetails] = useState([]);
  const [overallRating, setOverallRating] = useState("");

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(`/User/${user_id}`)
      .then((res) => {
        if (res.status === 200) {
          setUserDetails(res?.data?.User);
          setTaskDetails(res?.data?.Tasks);
          setOverallRating(res?.data?.Overall);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get profile details", "error");
      });
  }, []);
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
          <div className="ratings-card bg-white p-6 shadow-lg rounded-md">
            <h1 className="text-2xl text-gray-700 mb-2 font-semibold">
              Perfomance Details
            </h1>
            <hr className=" w-full border-gray-400 mb-4" />
            <h2 className="text-xl text-gray-700 mb-4 font-medium">
              Task Ratings
            </h2>
            {taskDetails.map((task, index) => (
              <>
                <div
                  key={index}
                  className="task-ratings-info flex justify-between items-center mb-3"
                >
                  <span className=" text-gray-700">
                    {index + 1}. {task.Task}
                  </span>
                  <span className="text-sm text-gray-700">
                    {task.Rating === null && task.Status === "Assigned"
                      ? "Not completed"
                      : task.Rating !== null && task.Status === "Assigned"
                      ? "Not completed"
                      : task.Rating === null && task.Status === "Completed"
                      ? "Not rated"
                      : Rating(task.Rating)}
                  </span>
                </div>
                <hr className="mb-2" />
              </>
            ))}

            <div className="mt-8 flex justify-between items-center">
              <p className="text-2xl text-gray-700">Overall Performance</p>
              {Rating(overallRating)}
            </div>
          </div>

          <div className="profile-card bg-white p-6 shadow-lg rounded-md">
            <div className="user-icon-box mb-4 flex justify-center">
              <FaUserCircle fontSize={160} color={"gray"} />
            </div>

            <hr className=" w-full border-gray-400 mb-4" />
            <div className="profile-info flex flex-col text-lg text-gray-700">
              <p className="mb-4">
                <span className="font-semibold">Name:</span> {userDetails?.Name}
              </p>
              <p className="mb-4">
                <span className="font-semibold">Email:</span>{" "}
                {userDetails.Email}
              </p>
              <p className="mb-4">
                <span className="font-semibold">Phone Number:</span>{" "}
                {userDetails.PhoneNumber}
              </p>
              <p className="mb-4">
                <span className="font-semibold">Internship Position:</span>{" "}
                {userDetails.Position}
              </p>
              <p>
                <span className="font-semibold">Supervisor:</span>{" "}
                {userDetails.Supervisor}
              </p>
            </div>
            <div className="mt-4 flex justify-end">
              <Link
                to={`/internDashboard/editProfile/${userDetails.user_id}`}
                className="text-sky-500 font-bold rounded-md hover:underline ease-linear transition-all duration-150"
              >
                Edit Profile
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Profile;
