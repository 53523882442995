import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";

function AssignSupervisor() {
  const [Loading, setLoading] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(false);
  const [supervisors, setSupervisors] = useState([]);
  const [interns, setInterns] = useState([]);
  const [supervisorID, setSupervisorID] = useState("");
  const [internID, setInternID] = useState("");
  const [message, setMessage] = useState({});
  const navigate = useNavigate();

  const getInterns = () => {
    setLoading(true);
    axiosClient
      .get(`/Admin/User/Interns`)
      .then((res) => {
        if (res.status === 200) {
          setInterns(res?.data?.Interns);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Intern Details");
      });
  };

  const getSupervisors = () => {
    setLoading(true);
    axiosClient
      .get(`Admin/User/Supervisors`)
      .then((res) => {
        if (res.status === 200) {
          setSupervisors(res?.data?.Supervisors);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Supervisor Details");
      });
  };

  useEffect(() => {
    getSupervisors();
    getInterns();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    const data = {
      InternID: internID,
      SupervisorID: supervisorID,
    };

    axiosClient
      .put(`/User/${internID}`, data)
      .then((res) => {
        swal("Success", "Supervisor Assigned", "success");
        setInternID("");
        setSupervisorID("");
        setSubmitLoading(false);
        setMessage({});
        navigate("/adminDashboard/interns");
      })
      .catch((error) => {
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could Not Assign Supervisor", "error");
        }
        setSubmitLoading(false);
      });
  };

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <>
          <h2 className="text-2xl mb-4 font-semibold text-gray-700">
            Assign Supervisor to Intern
          </h2>

          <div className="text-gray-700 flex flex-col bg-white rounded-md shadow-lg">
            <div className="form-box p-4">
              <form onSubmit={handleSubmit}>
                <div className="input-container mb-6">
                  <label htmlFor="position" className="font-semibold text-lg">
                    Intern
                  </label>
                  <select
                    name="Intern"
                    id="intern"
                    onChange={(e) => setInternID(e.target.value)}
                    className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                  >
                    <option value="">Select an Intern</option>
                    {interns.map((item) => (
                      <option key={item.user_id} value={item.user_id}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                  <span className="text-red-400">{message?.InternID}</span>
                </div>
                <div className="input-container mb-6">
                  <label htmlFor="position" className="font-semibold text-lg">
                    Supervisor
                  </label>
                  <select
                    name="Supervisor"
                    id="supervisor"
                    onChange={(e) => setSupervisorID(e.target.value)}
                    className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                  >
                    <option value="">Select a supervisor</option>
                    {supervisors.map((item) => (
                      <option key={item.user_id} value={item.user_id}>
                        {item.Name}
                      </option>
                    ))}
                  </select>
                  <span className="text-red-400">{message?.SupervisorID}</span>
                </div>
                <div className="flex justify-end">
                  <button className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150">
                    {SubmitLoading ? <LoadingComponent /> : "Assign"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AssignSupervisor;
