import React, { Fragment, useState } from "react";
import { HiUserCircle, HiOutlineMenu } from "react-icons/hi";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { Transition, Menu } from "@headlessui/react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import axiosClient from "../../AxiosClient";
import LoadingScreen from "../../LoadingScreen";
import swal from "sweetalert";

function Navbar(props) {
  const navigate = useNavigate();
  const { setAuth, setToken } = useStateContext();
  const [Loading, setLoading] = useState(false);
  const username = localStorage.getItem("Name");

  const logout = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosClient
      .post("/logout")
      .then(() => {
        setLoading(false);
        setAuth({});
        setToken(null);
        localStorage.removeItem("Roles");
        localStorage.removeItem("Name");
        localStorage.removeItem("user_id");
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not logout", "error");
      });
  };

  return (
    <>
      <div
        className={`bg-white h-16 px-4 rounded-md flex justify-between items-center border-b border-gray-300 shadow-md my-2 mx-2`}
      >
        <div>
          <HiOutlineMenu
            onClick={() => props.setOpen(!props.open)}
            fontSize={24}
            className="cursor-pointer xl:hidden"
          />
        </div>

        <div className="flex items-center ">
          <Menu as="div" className="relative text-gray-700">
            <div>
              <Menu.Button className="ml-2 inline-flex rounded-full ring-2 ring-neutral-400">
                <span className="sr-only">Open user menu</span>

                <div className="flex items-center gap-2">
                  <HiUserCircle fontSize={30} />
                  <span className="flex items-center">
                    {username}
                    <MdOutlineArrowDropDown fontSize={21} />
                  </span>
                </div>
              </Menu.Button>
            </div>
            {Loading ? (
              <LoadingScreen />
            ) : (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-lg p-1 bg-white ring-2 ring-black ring-opacity-5 focus:outline-none ">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active && "bg-gray-100",
                          "text-gray-700 focus:bg-gray-200 cursor-pointer rounded-sm px-4 py-2"
                        )}
                        onClick={() => navigate("/internDashboard/profile")}
                      >
                        My Profile
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active && "bg-gray-100",
                          "text-gray-700 focus:bg-gray-200 cursor-pointer rounded-sm px-4 py-2"
                        )}
                        onClick={() =>
                          navigate("/internDashboard/resetPassword")
                        }
                      >
                        Reset Password
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active && "bg-gray-100",
                          "text-red-700 font-bold focus:bg-gray-200 cursor-pointer rounded-sm px-4 py-2"
                        )}
                        onClick={logout}
                      >
                        Logout
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            )}
          </Menu>
        </div>
      </div>
    </>
  );
}

export default Navbar;
