import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import Rating from "../consts/ratings";
import TaskStatus from "../consts/task-status";
import LoadingComponent from "../LoadingComponent";
import LoadingScreen from "../LoadingScreen";

function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [ReloadTask, setReloadTask] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get("/Task")
      .then((res) => {
        setTasks(res?.data?.Tasks);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Tasks", "error");
      });
  }, []);

  const Complete = (id, index) => {
    setReloadTask(index);
    axiosClient
      .put(`Task/Status/${id}`)
      .then((res) => {
        if (res.status === 200) {
          axiosClient.get("/Task").then((res) => {
            setTasks(res?.data?.Tasks);
            setReloadTask(false);
          });
        }
      })
      .catch(() => {
        setReloadTask(false);
        swal("Error", "Could not Complete Task", "error");
      });
  };

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {tasks.length > 0 ? (
            tasks.map((task, index) => (
              <div
                key={task.id}
                className="bg-white rounded-sm p-4 flex flex-col shadow-md "
              >
                {ReloadTask === index ? (
                  <LoadingComponent />
                ) : (
                  <>
                    <div className="rounded-full h-15 flex items-center justify-start mb-4">
                      <input
                        type="checkbox"
                        className="h-[2rem] w-[1.2rem]"
                        onClick={() => {
                          Complete(task.id, index);
                        }}
                        defaultChecked={
                          task?.Status === "Completed" ? "checked" : ""
                        }
                      />
                      <span className="text-xl text-gray-700 font-medium pl-4">
                        {task.Task}
                      </span>
                    </div>
                    <div className="flex gap-4 items-center text-gray-700 ">
                      <span className="capitalize py-1 px-2 rounded-md text-xs text-red-600 bg-red-100">
                        {`Deadline: ${task.Deadline}`}
                      </span>
                      {TaskStatus(task.Status)}
                      {task?.Status === "Completed" && task.Rating !== null
                        ? Rating(task?.Rating)
                        : null}
                      <Link
                        to={`/internDashboard/tasks/${task.id}`}
                        className="text-xs text-gray-600 underline"
                      >
                        View More
                      </Link>
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <p className="text-lg">You haven't been assigned any task yet</p>
          )}
        </div>
      )}
    </>
  );
}

export default Tasks;
