import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import LoadingComponent from "../LoadingComponent";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";

function AddSupervisor() {
  const [fetchedPositions, setfetchedPositions] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [message, setMessage] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    axiosClient
      .get("/Position")
      .then((res) => {
        setfetchedPositions(res.data.Positions);
      })
      .catch(() => {
        swal("Error", "Could not get Position Details");
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      Name: name,
      Email: email,
      PhoneNumber: phone,
      Position: position,
      Role: "SUP",
    };

    axiosClient
      .post("/User", data)
      .then((res) => {
        if (res.status === 201) {
          setName("");
          setEmail("");
          setPhone("");
          setPosition("");
          setMessage({});
          swal("Success", "Supervisor Created", "success");
          setLoading(false);
          navigate("/adminDashboard/supervisors");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not create supervisor", "error");
        }
      });
  };
  return (
    <>
      <h2 className="text-2xl mb-4 font-semibold text-gray-700">
        Add New Supervisor
      </h2>

      <div className="text-gray-700 flex flex-col bg-white rounded-md shadow-lg">
        <div className="form-box p-4">
          <form onSubmit={handleSubmit}>
            <div className="input-container mb-6 w-full">
              <label htmlFor="name" className="font-semibold text-lg">
                Name
              </label>
              <input
                type="text"
                name="Name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              />
              <span className="text-red-400">{message?.Name}</span>
            </div>
            <div className="input-container mb-6 w-full">
              <label htmlFor="email" className="font-semibold text-lg">
                Email
              </label>
              <input
                type="email"
                name="Email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              />
              <span className="text-red-400">{message?.Email}</span>
            </div>
            <div className="input-container mb-6 w-full">
              <label htmlFor="phoneNumber" className="font-semibold text-lg">
                Phone Number
              </label>
              <PhoneInput
                international={true}
                countryCallingCodeEditable={false}
                country="KE"
                defaultCountry="KE"
                value={phone}
                onChange={setPhone}
                className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              />
              <span className="text-red-400">{message?.PhoneNumber}</span>
            </div>
            <div className="input-container mb-6">
              <label htmlFor="position" className="font-semibold text-lg">
                Department
              </label>
              <select
                name="Position"
                id="position"
                defaultValue={position}
                onChange={(e) => setPosition(e.target.value)}
                className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              >
                <option value="">Select a choice</option>
                {fetchedPositions.map((item) => (
                  <option key={item.id} value={item.Position}>
                    {item.Position}
                  </option>
                ))}
              </select>
              <span className="text-red-400">{message?.Position}</span>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
              >
                {Loading ? <LoadingComponent /> : "Add"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSupervisor;
