import React, { useState } from "react";
import axiosClient from "./AxiosClient";
import LoadingComponent from "./LoadingComponent";
import swal from "sweetalert";
import { useStateContext } from "./contexts/ContextProvider";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({});
  const [Loading, setLoading] = useState(false);
  const { setAuth, setToken } = useStateContext();

  const navigate = useNavigate();

  const logout = () => {
    setAuth({});
    setToken(null);
    localStorage.removeItem("Roles");
    localStorage.removeItem("Name");
    localStorage.removeItem("user_id");
    navigate("/login");
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("email", email);
    setLoading(true);

    axiosClient
      .post("/PasswordReset", formData)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMessage({});
          setEmail("");
          swal({
            title: "Success!",
            text: "Please check your email!",
            icon: "success",
            timer: 3000,
            buttons: false,
          });
          setTimeout(() => {
            logout();
          }, 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not send request", "error");
        }
      });
  };

  return (
    <section className="flex items-center justify-center">
      <div className="form-container bg-white rounded-lg lg:w-96 md:w-96 p-8 text-gray-700 shadow-lg sm:w-auto">
        <h1 className="text-3xl font-bold mb-4">Reset Password</h1>

        <div className="input-container mb-2">
          <label htmlFor="email" className="font-semibold text-lg">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
          />
          <span className="text-xs">
            * Please use the same email as used while logging in
          </span>
          <span className="text-red-400 block">{message?.email}</span>
        </div>
        <button
          className="mt-1 w-full py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
          onClick={() => {
            swal({
              title: "Warning",
              text: "Do you want to request a password reset? You will be logged out!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((value) => {
              if (value) {
                handleSubmit();
              }
            });
          }}
        >
          {Loading ? <LoadingComponent /> : "Send Request"}
        </button>
      </div>
    </section>
  );
}

export default ResetPassword;
