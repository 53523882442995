import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "./AxiosClient";
import LoadingComponent from "./LoadingComponent";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function CreateApplication() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [cv, setCV] = useState(null);
  const [letter, setLetter] = useState(null);

  const [errors, setErrors] = useState({});

  const [fetchedPositions, setfetchedPositions] = useState([]);
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    axiosClient
      .get("/Position")
      .then((res) => {
        setfetchedPositions(res?.data?.Positions);
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Positions", "error");
      });
  }, []);

  const cvRef = useRef();
  const letterRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", name);
    formData.append("Email", email);
    formData.append("PhoneNumber", phone);
    formData.append("Position", position);
    formData.append("Cv", cv);
    formData.append("AttachmentLetter", letter);

    setLoading(true);
    axiosClient
      .post("/Apply", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          setLoading(false);
          swal(
            "Success",
            "You will receive an email regarding your application",
            "success"
          );
          setName("");
          setEmail("");
          setPhone("");
          setPosition("");
          setErrors({});
          cvRef.current.value = null;
          letterRef.current.value = null;
          navigate("/applicationStatus");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setErrors(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not make an Application", "error");
        }
      });
  };
  return (
    <section className="bg-slate-200 flex flex-col ">
      <div className="p-4 status-btn-box h-30 lg:px-12 lg:py-4 sm:px-8 sm:py-6 flex justify-end gap-5 ">
        <Link
          to={"/applicationStatus"}
          className="py-2 px-4 bg-orange-500 hover:bg-orange-600 text-white rounded-md font-bold ease-linear transition-all duration-150"
        >
          Check Application Status
        </Link>
        <Link
          to={"/login"}
          className="py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md font-bold ease-linear transition-all duration-150"
        >
          Log In
        </Link>
      </div>
      <div className="grid-container mx-2 my-2 grid grid-cols-1 lg:grid-cols-2 lg:mx-12 lg:my-4 sm:mx-8 shadow-lg">
        <div className="info-box bg-slate-50 p-8 flex flex-col items-center justify-center rounded-t-md lg:rounded-tl-md lg:rounded-tr-none lg:rounded-bl-md lg:rounded-br-none">
          <img
            src={require("./logo-color.png")}
            alt="Oaknet Business Logo"
            className="h-24 mb-8"
          />
          <p className="description text-gray-700 text-center text-base mb-8">
            Oaknet Business Limited is a technology services firm, that helps
            our clients deploy the right technologies cost-effectively and
            efficiently. We ensure project objectives are met in time, at the
            right cost and with the expected impact.
          </p>
          <div className="services">
            <h1 className="font-bold text-gray-700 text-2xl mb-5 text-center">
              Services Provided
            </h1>
            <div className="service-cards grid lg:grid-cols-3 gap-3 sm:grid-cols-1 text-gray-700">
              <div className="card bg-white shadow-lg p-6">
                <h2 className="card-title text-lg font-bold mb-4">Advisory</h2>
                <p className="service-description text-[0.9rem]">
                  Oaknet Business Limited prides itself in offering advisory
                  services in{" "}
                  <span className="font-semibold">
                    {" "}
                    Strategy, Project Management, Design and Digital
                    Transformation.
                  </span>
                </p>
              </div>
              <div className="card bg-white shadow-lg p-6">
                <h2 className="card-title text-lg font-bold mb-4">
                  Engineering
                </h2>
                <p className="service-description text-[0.9rem]">
                  We offer a wide variety of engineering service such as{" "}
                  <span className="font-semibold">
                    {" "}
                    MEP (Mechanical Electrical and Plumbing), Smart Buildings,
                    Smart Cities and Public Security and Safety.
                  </span>
                </p>
              </div>
              <div className="card bg-white shadow-lg p-6">
                <h2 className="card-title text-lg font-bold mb-4">
                  Technology
                </h2>
                <p className="service-description text-[0.9rem]">
                  We also offer a wide variety of technology service such as{" "}
                  <span className="font-semibold">
                    {" "}
                    Software, Big Data, Smart Data Centers, Control Rooms and
                    Internet of Things.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="form-box bg-white p-8 rounded-b-md lg:rounded-tr-md lg:rounded-br-md lg:rounded-bl-none flex flex-col items-center">
          <h2 className="text-center text-2xl text-gray-700 font-medium mb-4">
            Send us your Internship Application
          </h2>
          <hr className="mb-6 border-gray-500 border-2 w-20" />

          <div className="form-container text-gray-700 ">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="input-container mb-6">
                <label htmlFor="name" className="font-semibold text-lg">
                  Name
                </label>
                <input
                  type="text"
                  name="Name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                />
                <span className="text-red-400">{errors?.Name}</span>
              </div>
              <div className="input-container mb-6">
                <label htmlFor="email" className="font-semibold text-lg">
                  Email
                </label>
                <input
                  type="email"
                  name="Email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                />
                <span className="text-red-400">{errors?.Email}</span>
              </div>
              <div className="input-container mb-6">
                <label htmlFor="phone" className="font-semibold text-lg">
                  Phone Number
                </label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  country="KE"
                  defaultCountry="KE"
                  value={phone}
                  onChange={setPhone}
                  className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                />
                <span className="text-red-400">{errors?.PhoneNumber}</span>
              </div>
              <div className="input-container mb-6">
                <label htmlFor="position" className="font-semibold text-lg">
                  What are you interested in?
                </label>
                <select
                  name="Position"
                  id="position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
                >
                  <option value="">Select a choice</option>
                  {fetchedPositions.map((item) => (
                    <option key={item.id} value={item.Position}>
                      {item.Position}
                    </option>
                  ))}
                </select>
                <span className="text-red-400">{errors?.Position}</span>
              </div>
              <div className="input-container mb-6">
                <label htmlFor="cv" className="font-semibold text-lg">
                  CV
                </label>
                <input
                  type="file"
                  name="Cv"
                  id="cv-letter"
                  accept="application/pdf"
                  onChange={(e) => setCV(e.target.files[0])}
                  ref={cvRef}
                  className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 p-1 focus:outline-gray-500"
                />
                <span className="text-red-400">{errors?.Cv}</span>
              </div>
              <div className="input-container mb-6">
                <label htmlFor="cv-letter" className="font-semibold text-lg">
                  University Letter
                </label>
                <input
                  type="file"
                  name="AttachmentLetter"
                  id="cv-letter"
                  accept="application/pdf"
                  onChange={(e) => setLetter(e.target.files[0])}
                  ref={letterRef}
                  className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 p-1 focus:outline-gray-500"
                />
                <span className="text-red-400">{errors?.AttachmentLetter}</span>
              </div>
              <button
                type="submit"
                className="w-full py-2 px-8 bg-emerald-500 text-white rounded-md font-bold hover:bg-emerald-600 ease-linear transition-all duration-150"
              >
                {Loading ? <LoadingComponent /> : "Send"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateApplication;
