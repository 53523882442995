import React, { useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "./AxiosClient";
import Status from "./consts/application-status";
import LoadingComponent from "./LoadingComponent";

function ApplicationStatus() {
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState({});
  const [email, setEmail] = useState("");
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Email", email);
    setLoading(true);

    axiosClient
      .post("/ApplicationStatus", formData)
      .then((res) => {
        if (res.status === 200) {
          setEmail("");
          setStatus(res.data.ApplicationStatus);
          setLoading(false);
          setShowModal(true);
          setMessage({});
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Email does not exist", "error");
        }
      });
  };

  return (
    <section className="px-4 h-screen bg-slate-200 flex items-center justify-center lg:px-0">
      <div className="form-container bg-white rounded-lg w-96 p-8 text-gray-700 shadow-lg">
        <h1 className="text-3xl font-bold mb-5">Application Status</h1>
        <p className="info mb-4">
          Provide your email address to check your application status
        </p>
        <form onSubmit={handleSubmit}>
          <div className="input-container mb-6">
            <label htmlFor="email" className="font-semibold text-lg">
              Email
            </label>
            <input
              type="email"
              name="Email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
            />
            <span className="text-red-400">{message?.Email}</span>
          </div>
          <button
            type="submit"
            className="mt-2 w-full py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
          >
            {Loading ? <LoadingComponent /> : "Check Status"}
          </button>
          <div className="text-center text-sm mt-4">
            <Link to="/" className="text-green-500 underline">
              Home
            </Link>
          </div>
        </form>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Your Application Status
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="my-4 text-slate-500 text-lg leading-relaxed">
                    <table className="border border-solid">
                      <tbody>
                        <tr>
                          <th className="border-2 border-gray-300 p-2 text-left">
                            Name
                          </th>
                          <td className="border-2 border-gray-300 p-2 text-left">
                            {status.Name}
                          </td>
                        </tr>
                        <tr>
                          <th className="border-2 border-gray-300 p-2 text-left">
                            Email
                          </th>
                          <td className="border-2 border-gray-300 p-2 text-left">
                            {status.Email}
                          </td>
                        </tr>
                        <tr>
                          <th className="border-2 border-gray-300 p-2 text-left">
                            Position
                          </th>
                          <td className="border-2 border-gray-300 p-2 text-left">
                            {status.Position}
                          </td>
                        </tr>
                        <tr>
                          <th className="border-2 border-gray-300 p-2 text-left">
                            Application Status
                          </th>
                          <td className="border-2 border-gray-300 p-2 text-left">
                            {Status(status.ApplicationStatus)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </section>
  );
}

export default ApplicationStatus;
