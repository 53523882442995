import React, { useEffect, useState } from "react";
import { FaFileUpload, FaUsers } from "react-icons/fa";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { BiSelectMultiple } from "react-icons/bi";
import axiosClient from "../AxiosClient";
import LoadingScreen from "../LoadingScreen";
import swal from "sweetalert";
import { Link } from "react-router-dom";

function Statistics() {
  const [Loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({});

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get(`Admin/User/Dashboard`)
      .then((res) => {
        if (res.status === 200) {
          const count = res?.data;
          setCounts({ count });
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Statistics Details", "error");
      });
  }, []);
  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="flex items-center gap-4 border-2 border-orange-500 w-fit p-3 bg-white">
            <span className="text-2xl text-gray-500 font-light">
              Total Users
            </span>
            <div>
              <strong className="text-2xl text-gray-700 font-semibold">
                {counts?.count?.TotalUsers}
              </strong>
            </div>
          </div>

          <div className="stats grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <Link to={"/adminDashboard/applications"}>
              <div className="bg-white rounded-sm p-4 flex-1 border-2 border-sky-300 flex items-center shadow-md hover:border-sky-600">
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
                  <FaFileUpload className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                  <span className="text-xl text-gray-500 font-light">
                    Applications
                  </span>
                  <div className="flex items-center">
                    <strong className="text-2xl text-gray-700 font-semibold">
                      {counts?.count?.TotalApplicants}
                    </strong>
                  </div>
                </div>
              </div>
            </Link>
            <Link to={"/adminDashboard/selectedApplicants"}>
              <div className="bg-white rounded-sm p-4 flex-1 border-2 border-purple-300 flex items-center shadow-md hover:border-purple-600">
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-purple-500">
                  <BiSelectMultiple className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                  <span className="text-xl text-gray-500 font-light">
                    Selected Applicants
                  </span>
                  <div className="flex items-center">
                    <strong className="text-2xl text-gray-700 font-semibold">
                      {counts?.count?.SelectedApplicants}
                    </strong>
                  </div>
                </div>
              </div>
            </Link>
            <Link to={"/adminDashboard/interns"}>
              <div className="bg-white rounded-sm p-4 flex-1 border-2 border-green-400 flex items-center shadow-md hover:border-green-600">
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-500">
                  <FaUsers className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                  <span className="text-xl text-gray-500 font-light">
                    Interns
                  </span>
                  <div className="flex items-center">
                    <strong className="text-2xl text-gray-700 font-semibold">
                      {counts?.count?.TotalInterns}
                    </strong>
                  </div>
                </div>
              </div>
            </Link>

            <Link to={"/adminDashboard/supervisors"}>
              <div className="bg-white rounded-sm p-4 flex-1 border-2 border-red-300 flex items-center shadow-md hover:border-red-600">
                <div className="rounded-full h-12 w-12 flex items-center justify-center bg-red-500">
                  <MdOutlineSupervisorAccount className="text-2xl text-white" />
                </div>
                <div className="pl-4">
                  <span className="text-xl text-gray-500 font-light">
                    Supervisors
                  </span>
                  <div className="flex items-center">
                    <strong className="text-2xl text-gray-700 font-semibold">
                      {counts?.count?.TotalSupervisors}
                    </strong>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </>
      )}
    </>
  );
}

export default Statistics;
