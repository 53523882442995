import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import LoadingComponent from "../LoadingComponent";

function AssignTask() {
  const { name, id } = useParams();
  const [Task, setTask] = useState("");
  const [TaskDescription, setTaskDescription] = useState("");
  const [Deadline, setDeadline] = useState("");
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("AssignedTo", id);
    formData.append("Task", Task);
    formData.append("TaskDescription", TaskDescription);
    formData.append("Deadline", Deadline);
    setLoading(true);
    axiosClient
      .post("/Task", formData)
      .then((res) => {
        if (res.status === 201) {
          setLoading(false);
          swal("Success", "Task Assigned Successfully", "success");
          setTask("");
          setTaskDescription("");
          setDeadline("");
          setMessage({});
          navigate("/supervisorDashboard/assignedTasks");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response && error?.response?.status === 422) {
          setMessage(error?.response?.data?.errors);
        } else {
          swal("Error", "Could not assign Task", "error");
        }
      });
  };

  return (
    <>
      <h2 className="text-2xl mb-4 font-semibold text-gray-700">
        Assign a Task
      </h2>

      <div className="text-gray-700 flex flex-col bg-white rounded-md shadow-lg">
        <div className="form-box p-4">
          <form onSubmit={handleSubmit}>
            <div className="input-container mb-6 w-full">
              <label htmlFor="ID" className="font-semibold text-lg">
                Intern Name
              </label>
              <input
                type="text"
                name="AssignedTo"
                id="Name"
                value={name}
                readOnly
                className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              />
            </div>
            <div className="input-container mb-6 w-full">
              <label htmlFor="task" className="font-semibold text-lg">
                Task
              </label>
              <input
                type="text"
                name="Task"
                id="task"
                value={Task}
                onChange={(e) => setTask(e.target.value)}
                className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              />
              <span className="text-red-400">{message?.Task}</span>
            </div>
            <div className="input-container mb-4 w-full">
              <label
                htmlFor="taskDescription"
                className="font-semibold text-lg"
              >
                Task Description
              </label>
              <textarea
                name="Description"
                id="taskDescription"
                cols="30"
                rows="3"
                value={TaskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
                className="w-full mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 py-2 focus:outline-gray-500"
              ></textarea>
              <span className="text-red-400">{message?.TaskDescription}</span>
            </div>
            <div className="input-container mb-6 w-full">
              <label htmlFor="deadline" className="font-semibold text-lg">
                Deadline
              </label>
              <input
                type="date"
                name="Deadline"
                id="deadline"
                min={new Date().toISOString().split("T")[0]}
                value={Deadline}
                onChange={(e) => setDeadline(e.target.value)}
                className="w-full h-10 mt-1 rounded-md border-2 border-gray-300 text-gray-700 pl-2 focus:outline-gray-500"
              />
              <span className="text-red-400">{message?.Deadline}</span>
            </div>
            <div className="flex justify-end">
              <button
                className="mt-1 w-full lg:w-40 py-2 bg-emerald-500 text-white font-bold rounded-md hover:bg-emerald-600 ease-linear transition-all duration-150"
                type="submit"
              >
                {Loading ? <LoadingComponent /> : "Assign Task"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AssignTask;
