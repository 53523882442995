import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import axiosClient from "../AxiosClient";
import Status from "../consts/application-status";
import LoadingScreen from "../LoadingScreen";

function ViewDetails() {
  const [Loading, setLoading] = useState(false);
  const [Details, setDetails] = useState({});
  const [cv, setCV] = useState("");
  const [letter, setLetter] = useState("");

  const { id } = useParams();

  const getApplicantDetails = () => {
    setLoading(true);
    axiosClient
      .get(`Apply/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const applicant = res?.data?.Applicant;
          setDetails(applicant);
          setCV(res?.data?.Cv);
          setLetter(res?.data.AttachmentLetter);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        swal("Error", "Could not get Intern Details", "error");
      });
  };

  useEffect(() => {
    getApplicantDetails();
  }, []);

  return (
    <>
      {Loading ? (
        <LoadingScreen />
      ) : (
        <div className="flex items-center justify-start">
          <table className="border border-solid ">
            <tbody>
              <tr>
                <th className="border-2 border-gray-300 p-2 text-left">Name</th>
                <td className="border-2 border-gray-300 p-2 text-left">
                  {Details?.Name}
                </td>
              </tr>
              <tr>
                <th className="border-2 border-gray-300 p-2 text-left">
                  Email
                </th>
                <td className="border-2 border-gray-300 p-2 text-left">
                  {Details?.Email}
                </td>
              </tr>
              <tr>
                <th className="border-2 border-gray-300 p-2 text-left">
                  Phone Number
                </th>
                <td className="border-2 border-gray-300 p-2 text-left">
                  {Details?.PhoneNumber}
                </td>
              </tr>
              <tr>
                <th className="border-2 border-gray-300 p-2 text-left">
                  Application For
                </th>
                <td className="border-2 border-gray-300 p-2 text-left">
                  {Details?.Position}
                </td>
              </tr>
              <tr>
                <th className="border-2 border-gray-300 p-2 text-left">
                  Application Status
                </th>
                <td className="border-2 border-gray-300 p-2 text-left">
                  {Status(Details?.ApplicationStatus)}
                </td>
              </tr>
              <tr>
                <th className="border-2 border-gray-300 p-2 text-left">CV</th>
                <td className="border-2 border-gray-300 p-2 text-left">
                  <button
                    className="bg-orange-500 px-2 py-[0.1rem] text-white font-medium hover:bg-orange-600 rounded-md ease-linear transition-all duration-150"
                    onClick={() => {
                      window.open(cv);
                    }}
                  >
                    View
                  </button>
                </td>
              </tr>
              <tr>
                <th className="border-2 border-gray-300 p-2 text-left">
                  Univeristy Letter
                </th>
                <td className="border-2 border-gray-300 p-2 text-left">
                  <button
                    className="bg-orange-500 px-2 py-[0.1rem] text-white font-medium hover:bg-orange-600 rounded-md ease-linear transition-all duration-150"
                    onClick={() => {
                      window.open(letter);
                    }}
                  >
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default ViewDetails;
