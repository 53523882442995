import React from "react";
import Statistics from "./Statistics";

function Dashboard() {
  return (
    <div className="flex flex-col gap-4">
      <Statistics />
    </div>
  );
}

export default Dashboard;
